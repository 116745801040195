<template>
  <div class="homepage-banner">
    <img id="banner-background" src="../../assets/mobile-banner-background.jpg" />
    <div class="banner-content">
      <div class="banner-text-container">
        <span>Download Scanner+ now & scan, edit, translate, sign or share your documents!</span>
        <store-badges id="banner-badge-container" :scale="0.65" style="margin-bottom: 0;" />
      </div>
      <img id="banner-image" src="@/assets/banner-phone.png" />
    </div>
  </div>
</template>

<script>

import StoreBadges from "@/components/common/StoreBadges";

export default {
  name: "HomepageBanner",
  components: {
    "store-badges": StoreBadges
  }
}
</script>

<style scoped>
.homepage-banner {
  width: 100%;
  height: calc(var(--oh) * 49);
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 10px;
  box-sizing: border-box;
}

.banner-content {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
}
.banner-text-container {
  display: flex;
  width: 90%;
  min-width: 90%;
  flex-shrink: 0;
  height: 45%;
  padding: 0 20px;
  padding-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.banner-text-container span {
  font-weight: 600;
  font-size: calc(var(--oh) * 2);
  line-height: 150%;
  color: #FFFFFF;
  text-align: justify;
  margin: 0 18vw;
}
#banner-badge-container {
  display: flex;
  justify-content: space-around;
  align-items: center;


}
#banner-image {
  width: 61.4%;

}
#banner-background {
  position: absolute;
  left: 0;
  top: 0;
  height: 83.33%;
  width: 100%;
  transform: translateY(calc(var(--oh) * 8.17));
}
</style>